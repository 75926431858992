import { MessageOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import ChatModal from './ChatModal/ChatModal';

interface StickyChatProps {
    context: any;
    title: string;
}

const StickyChat = ({context, title}: StickyChatProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
        {/* <Button type="primary" shape="circle" icon={<MessageOutlined />}style={{position: "fixed", bottom: 40, right: 40}} onClick={showModal}></Button>
        <ChatModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} context={context} /> */}
        <Button type="primary" icon={<MessageOutlined style={{ fontSize: '30px'}}/>} shape="circle" style={{position: "fixed", bottom: 40, right: 40, height: "60px", width: "60px"}} onClick={showModal}></Button>
        <ChatModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} context={context} title={title} />
        </>
    )
}

export default StickyChat