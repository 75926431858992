import React, {useState, useEffect} from 'react'
import { Layout, Row, Col, Typography, Card, Collapse, Spin, Flex, Upload, message, Button, Select, Divider, Alert } from 'antd';
import  ImageUploader  from '../../ImageUploader/ImageUploader';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import EditButton from '../../ButtonComponents/EditButton';
import DownloadDocButton from '../../ButtonComponents/DownloadDocButton';
import Feedback from '../../Feedback/Feedback';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import { useAuth } from '../../../auth/AuthContext';
import { DeleteOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import DeidButton from '../../ButtonComponents/DeidButton';
import StickyChat from '../../StickyChat/StickyChat';
const { Content } = Layout;
const {Title} = Typography;
const Panel = Collapse.Panel;
const { Dragger } = Upload;


const SysnopsisText = () => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [text, setText] = useState<string>('');
    const [synopsis, setSynopsis] = useState<any>({});
    const [deIDResponse, setDeIDResponse] = useState<any>('');
    const [augmentedResponse, setAugmentedResponse] = useState<any>('');
    const [options, setOptions] = useState<any>([])
    const [patientID, setPatientID] = useState<any>()
    const [ehr, setEHR] = useState<any>()
    const [authorizeButtonLoading, setAuthorizeButtonLoading] = useState<any>(false);
    const [authorized, setAuthorized] = useState<any>(false);
    const [epicEHRPatientDetails, setEpicEHRPatientDetails] = useState<any>([]);

    const authorizeEpic = async() => {
        setAuthorizeButtonLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/epic_auth`, {
              method: "POST",
              headers: {
                'Authorization': `Bearer ${auth.user['access_token']}`,
              },
            });
            const data = await response.json();
            setEpicEHRPatientDetails(data.patient_details)
            setOptions(data.patient_details.map((patient:any) => (
                {label: patient[1], value: patient[1]}
            )))
        } catch (error) {
            console.error('Authorization with Epic EHR Failed:', error);
            message.error('Authorization with Epic EHR Failed!');
        }   
        
        setAuthorizeButtonLoading(false);
        setAuthorized(true);
    }
    useEffect(() => {
        setOptions([
        {label: "5678-1234-ABCD", value: "5678-1234-ABCD"},
        {label: "9876-4321-ZYXW", value: "9876-4321-ZYXW"}
        ])
    }, []);

    const markdownToHtml = (markdown: any) => {
        const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
        return DOMPurify.sanitize(rawHtml as string);// Sanitize HTML to prevent XSS attacks
      };

    const handleFileChange = ({ fileList }: any, type?: string) => {
        const newList = fileList.map((file:any) => ({
            ...file,
            status: 'done',
            uid: file.uid,
            name: file.name,
            originFileObj: file.originFileObj || file,
        }));

        setFileList(newList);
        setPatientID(null);
        setSynopsis({});
    };

    const handleRemove = (uid:any) => {
        const newFileList = fileList.filter((file:any) => file.uid !== uid);
        setFileList(newFileList);
    };

    const beforeUpload = (file:any) => {
        const isSupportedFileType = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isSupportedFileType) {
            message.error('You can only upload PDF or DOCX files!');
        }
        if (fileList.length >= 2) {
            message.error('You can only upload up to 2 files!');
            return Upload.LIST_IGNORE;
        }
        return isSupportedFileType;
    };

    const customRequest = ({ file, onSuccess }: any) => {
        // Simulate an immediate successful upload
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const fileUploadProps = {
        customRequest,
        name: 'file',
        multiple: true,
        fileList,
        onChange: (info:any) => handleFileChange(info),
        beforeUpload,
        onRemove: (file:any) => {
            handleRemove(file.uid);
            return true;
        },
        showUploadList: {
            showPreviewIcon: true,
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined onClick={event => handleRemove(event.currentTarget.getAttribute('data-uid'))} />,
        },
        accept: ".pdf, .docx",
        maxCount: 2
    };

    const handleSubmit = async () => {
        if (fileList.length === 0 && !text.trim() && !patientID.trim()) {
          message.error("Please select a Patient ID, or upload a medical note or enter chief complaints.");
          return;
        }
    
        setIsLoading(true);
        let formData = new FormData();
        let apiURL = "";
        if (!patientID.trim()){       
            if (text.trim()) {
                formData.append('patient_symptoms', text);
            }
                fileList.forEach((file:any) => {
                formData.append('documents', file.originFileObj);
            });
            apiURL = `${process.env.REACT_APP_API_URL}/generate_synopsis/`;
        }else {
            formData.append("ehr_type", ehr);
            formData.append('patient_id', patientID);
            apiURL = `${process.env.REACT_APP_API_URL}/generate_synopsis_from_ehr/`;
        }

        try {
          const response = await fetch(apiURL, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${auth.user['access_token']}`,
            },
            body: formData // This formData now includes both images and PDFs
          });
          const data = await response.json();
          setSynopsis(data);
          setFileList([]);
          setText('');
        } catch (error) {
          console.error('Generate Synopsis Failed:', error);
          message.error('Generate Synopsis Failed');
        }   
        setIsLoading(false);
    };
    return (
        <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
        <Title level={3}>Synopsis</Title>
        <Row gutter={16}>
            <Col span={8}>
                <Card className='max-height scrollY' style={{ backgroundColor: "#e5e5e5" }}>
                    <Title level={5} style={{marginTop: "0px"}}>Select EHR</Title>
                    <Select
                        placeholder="Select EHR"
                        allowClear
                        style={{width: "100%"}}
                        options={[
                            {label: "Epic EHR", value: "Epic EHR"},
                            {label: "Sporo EHR", value: "Sporo EHR"}
                            ]}
                        onChange={(value)=> {
                            value === "Sporo EHR"? setOptions([
                                {label: "5678-1234-ABCD", value: "5678-1234-ABCD"},
                                {label: "9876-4321-ZYXW", value: "9876-4321-ZYXW"}
                                ]):setOptions(epicEHRPatientDetails.map((patient:any) => (
                                    {label: patient[1], value: patient[1]}
                                )))
                            setEHR(value);
                            setPatientID('');
                            setFileList([]);
                            setText('');
                            // setAuthorized(false);
                        }}
                        value={ehr}
                    />
                    <br />
                    {ehr === 'Epic EHR'? 
                    <>
                    {authorized? <Alert message="Authorization with Epic EHR is successful." type="success" showIcon style={{  marginTop: "20px" }} /> : <div style={{ textAlign: "right", paddingTop: "20px" }}>
                    <Button disabled={true} type='primary' onClick={authorizeEpic} loading={authorizeButtonLoading}>{authorizeButtonLoading? "Authotrizing": "Authorize"}</Button>
                    </div>}
                    {authorized &&
                    <> 
                    <br /> 
                    <Title level={5} style={{marginTop: "0px"}}>Select a Patient</Title>
                    <Select
                        placeholder="Select a Patient"
                        allowClear
                        style={{width: "100%"}}
                        options={options}
                        onChange={(value)=> {
                            setPatientID(value);
                            setFileList([]);
                            setText('');
                        }}
                        value={patientID}
                    />
                    <br /> 
                    </>}
                    </>
                    :ehr === 'Sporo EHR'?
                    <>
                    <br />
                    <Title level={5} style={{marginTop: "0px"}}>Select a Patient ID</Title>
                    <Select
                        placeholder="Select a Patient ID"
                        allowClear
                        style={{width: "100%"}}
                        options={options}
                        onChange={(value)=> {
                            setPatientID(value);
                            setFileList([]);
                            setText('');
                        }}
                        value={patientID}
                    />
                    <br />  
                    </>
                    :
                    <></>
                    }                  
                    <div style={{ textAlign: "center" }}>
                    <Divider>
                        <span style={{ fontSize: '16px', color: '#888' }}>OR</span>
                    </Divider>
                    </div>   
                    <Title level={5}>Upload Medical Reports</Title>
                    <Dragger {...fileUploadProps}>
                        <p className="ant-upload-drag-icon">
                            <FilePdfOutlined /><FileWordOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag PDF/DOCX to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for uploading up to 2 files.
                        </p>
                    </Dragger>
                    <Title level={5} style={{marginTop: "20px"}}>Enter Chief Complaints</Title>
                    <TextArea
                        rows={9}
                        value={text}
                        onChange={(e:any) => {setText(e.target.value); setSynopsis({});setPatientID(null);}}
                        placeholder="Please enter your health notes here..."        
                    />

                    <div style={{ textAlign: "right", paddingTop: "20px" }}>
                        <Button type="primary" onClick={handleSubmit}>Generate Synopsis</Button>
                    </div>
                </Card>
            </Col>
            <Col span={16}>
            <Card className='max-height scrollY' style={{backgroundColor: "#fff"}}>
                {Object.keys(synopsis).length > 0?
                <Collapse accordion style={{ marginTop: "30px" }}>                
                    <Panel header="Synopsis" key="01">
                    <Flex style={{ justifyContent: 'flex-end' }}><DeidButton id={synopsis.process_id} text={synopsis.synopsis} deidType="synopsis" setDeIDResponse={setDeIDResponse} setIsLoading={setIsLoading} /><EditButton id={synopsis.process_id} text={synopsis.synopsis} editType={"synopsis"} setAugmentedResponse={setAugmentedResponse} /><CopytoClipboard text={synopsis.synopsis}/><DownloadDocButton markdownText={synopsis.synopsis} fileName={"Synopsis"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(synopsis.synopsis) }} /><Feedback submitionType="synopsis" id={synopsis.process_id} />
                    </Panel>
                    {deIDResponse.trim().length>0?
                    <Panel header="Anonymized Synopsis" key="02">
                    <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={deIDResponse}/><DownloadDocButton markdownText={deIDResponse} fileName={"Anonymized Synopsis"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(deIDResponse) }} />
                    </Panel>
                    :""}
                    {augmentedResponse.trim().length>0?
                    <Panel header="Final Synopsis" key="03">
                    <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={augmentedResponse}/><DownloadDocButton markdownText={augmentedResponse} fileName={"Final Synopsis"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(augmentedResponse) }} />
                    </Panel>
                    :""}
                </Collapse>
                :""}
                {isLoading? 
                <div style={{textAlign: "center", padding: "30px"}}>
                <Spin tip="Loading" size="large" />
                </div>: ""}
            </Card>
            </Col>
        </Row>
        {Object.keys(synopsis).length > 0
        &&
        <StickyChat context={synopsis.synopsis} title="Chat with Synopsis"/>
        }
        </Content>
    )
}

export default SysnopsisText;