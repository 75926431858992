import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Input, Tooltip } from 'antd';
import React, {useState} from 'react';
import { useAuth } from '../../auth/AuthContext';

interface EditButtonProps {
    id: string;
    text: string;
    editType: any;
    setAugmentedResponse: any;
}

const EditButton = ({id, text, editType, setAugmentedResponse}: EditButtonProps) => {
    const auth = useAuth();
    // const [loading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editedText, setEditedText] = useState(text);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleSave = async () => {
        // setIsLoading(true);
        try {
            let formData = new FormData();
            formData.append("process_id", id);
            formData.append("augmented_analysis", editedText);
            formData.append("edit_type", editType);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/edit_ai_generated_response`, {
                method: 'POST', // or 'PUT'
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setAugmentedResponse(editedText)
            message.success('Your notes are saved successfully');
            setIsModalVisible(false); // Close the modal on success
        } catch (error) {
            console.error('Failed to save the edited text: ', error);
            message.error('Failed to update text.');
        }
        // setIsLoading(false);
    };
  return (
    <div>
        <Tooltip placement="top" title="Edit">
        <Button type="primary" shape="circle" onClick={showModal} style={{backgroundColor: "#e78400", marginRight: "20px"}}><EditOutlined /></Button>
        </Tooltip>
    
    <Modal title="Edit Text" open={isModalVisible} onOk={handleSave} onCancel={handleCancel} okText="Save" cancelText="Cancel" centered width={800} maskClosable={false} keyboard={false} >
        <Input.TextArea rows={15} value={editedText} onChange={(e) => setEditedText(e.target.value)} />
        <small style={{color: "red", fontWeight: 600, fontStyle: "italic"}}><InfoCircleOutlined style={{marginRight: "10px"}} />Please maintain the same structure while editing.</small>
    </Modal>
  </div>
  )
}

export default EditButton