import { AudioOutlined, SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, List, Modal, Space, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth/AuthContext';
// import DummyFeedback from '../../../DummyComponent/DummyFeedback/DummyFeedback';
import './ChatModal.css';
import botAvatar from '../../../assets/images/bot-avatar.png';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import {marked} from 'marked'; // Import marked for markdown to HTML conversion
import DOMPurify from 'dompurify';


// Speech recognition setup
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition:any = null;
if (SpeechRecognition) {
    recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.lang = 'en-US';
    recognition.interimResults = false;
}

interface ChatModalProps {
    isModalOpen: boolean;
    handleOk: any;
    handleCancel: any;
    context: any;
    title: string;
}

interface ChatMessage {
    id: number;
    content: any;
    sender: 'you' | 'bot';
}

const initialMessages: ChatMessage[] = [
    { id: 1, content: "Hello, What can I do for you?", sender: 'bot' },
];

const ChatModal = ({isModalOpen, handleOk, handleCancel, context, title}: ChatModalProps) => {
    const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);
    const [newMessage, setNewMessage] = useState<string>('');
    const [recording, setRecording] = useState<boolean>(false);
    const auth = useAuth();
    const userDetails = localStorage.getItem("userDetails");
    const user = userDetails ? JSON.parse(userDetails) : {}; 

    useEffect(() => {
        if (!recognition) return;

        recognition.onresult = (event:any) => {
            const transcript = Array.from(event.results)
                .map((result:any) => result[0])
                .map((result:any) => result.transcript)
                .join('');
            setNewMessage(transcript);
            setRecording(false);
        };

        recognition.onend = () => {
            setRecording(false);
        };

        recognition.onerror = (event:any) => {
            console.error('SpeechRecognition error:', event.error);
            setRecording(false);
        };
    }, []);

    const handleRecord = () => {
        if (!SpeechRecognition) {
            console.error('SpeechRecognition is not supported in this browser.');
            return;
        }

        if (recording) {
            recognition.stop();
            setRecording(false);
        } else {
            recognition.start();
            setRecording(true);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;
    
        // User message
        const nextYouMessage: ChatMessage = {
            id: messages.length + 1,
            content: newMessage,
            sender: 'you',
        };
    
        // Temporary loading message for bot
        const loadingMessage: ChatMessage = {
            id: messages.length + 2,
            content: "Loading bot's response...", // This could be replaced with a spinner or any placeholder
            sender: 'bot',
        };
    
        // Update state with user message and temporary loading message
        setMessages(messages => [...messages, nextYouMessage, loadingMessage]);
        setNewMessage('');
        try {
            const botResponse = await fetchBotResponse(newMessage);
            
            // Replace the loading message with actual bot response
            setMessages(messages => messages.map(message => 
              message.id === loadingMessage.id ? { ...message, content: botResponse } : message
            ));
        } catch (error) {
            console.error("Fetching bot's response failed:", error);
            // Update loading message with error message
            setMessages(messages => messages.map(message => 
              message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
            ));
        }
    };

    const fetchBotResponse = async (query:any) => {
        try{
            let formData = new FormData();
            formData.append("query", query);
            formData.append("synopsis", context);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/chat_with_synopsis`, {
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                if(data.error){                    
                    return data.error 
                } 
                return data
            }
        }catch(err){
            return err
        }
    }

    const markdownToHtml = (markdown: any) => {
        const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
        return DOMPurify.sanitize(rawHtml as string);// Sanitize HTML to prevent XSS attacks
      };

    return (
        <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered width={400} maskClosable={false} footer={null} style={{ right: 60, position: 'fixed', margin: 0, top: 170}}>
            <div className="chat-container">
                <List
                    className="chat-list"
                    dataSource={messages}
                    renderItem={item => (
                    <List.Item key={item.id} className={`chat-message ${item.sender}`} style={{padding: "10px", borderRadius: "10px"}}>
                        {item.sender === 'bot' && (
                            <Avatar src={botAvatar} style={{backgroundColor: "#000000", padding:"5px"}} className="avatar-left" />
                        )}
                        <List.Item.Meta
                        title={item.sender === 'you' ? 'You' : 'Sporo Health'}
                        description={item.content === "Loading bot's response..." ? <Spin /> : item.sender === 'bot'? (<div /*style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}*/>
                        {item.id!==1&&<div style={{textAlign: "right"}}><CopytoClipboard text={item.content}/></div>}<div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(item.content) }} />
                        </div>) : item.content} // Show spinner if loading
                        />
                        
                        {item.sender === 'you' && (
                            <Avatar className="avatar-right" style={{backgroundColor: "#1677FF"}}>{user.fullname.split(" ")[0][0]}{user.fullname.split(" ")[1][0]}</Avatar>
                        )}
                    </List.Item>
                    )}
                />        
            </div>
            <Space.Compact style={{ width: '100%', marginTop: "20px"}}>
                <Button type="primary" onClick={handleRecord} icon={<AudioOutlined />} danger={recording} />
                <Input value={newMessage}
                onChange={handleInputChange}
                onPressEnter={handleSendMessage}
                placeholder="Ask your query..." />
                <Button type="primary" onClick={handleSendMessage} icon={<SendOutlined />}/>
            </Space.Compact>
            </Modal>
    )
}

export default ChatModal