// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { fakeAuth } from '../../auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme, Row, Col, Form, Typography, Input, Button } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import bgImage from '../../assets/images/bg.jpg';
import { useAuth } from '../../auth/AuthContext';

const { Header, Content, Footer } = Layout;
const {Title} = Typography;


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect to the dashboard if the user is already logged in
    if (auth.user) {
      navigate('/overview');
    }
  }, [auth.user, navigate]);

  const handleSubmit = async () => {
    // event.preventDefault();
    try {
      await auth.login(username, password);
      navigate('/overview'); // Redirect to the dashboard or another protected route after login
    } catch (error) {
      console.error("Login failed:", error);
      // Optionally handle login failure (e.g., displaying an error message)
    }
  };

  return (
    <Layout style={{minHeight: "100vh"}}>
        <img src={bgImage} alt="" style={{position: "absolute" , top: 0, left: 0, height: "100vh", width: "100vw"}} />
      {/* <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header> */}
      <Content style={{ padding: '12% 48px 0 48px', textAlign: "center" }}>
      <Row gutter={[24, 0]} justify="space-around">
              <Col
                sm={{span: 8}}
              />
              <Col
                sm={{span: 8}}
                
              >
                <Title className="mb-15">Sign In</Title>
                <Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title>
                <Form
                  style={{textAlign: "left"}}
                  layout="vertical"
                  className="row-col"
                  
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                    
                  >
                    <Input placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}  />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    
                  >
                    <Input type='password' placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Item>


                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ width: "100%", marginTop: "30px" }}
                      onClick={()=>handleSubmit()}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                  {/* <p className="font-semibold text-muted">
                    Don't have an account?{" "}
                    <Link to="/sign-up" className="text-dark font-bold">
                      Sign Up
                    </Link>
                  </p> */}
                </Form>
              </Col>
              <Col
                sm={{span:8}}
              >
                {/* <img src={bgImage} alt="" /> */}
              </Col>
            </Row>
      </Content>
      <AppFooter/>
    </Layout>
  );
};

export default Login;
