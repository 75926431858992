import React from 'react'
import { Layout, Row, Col, Card, Avatar, Form, Button, Input, Typography } from 'antd';
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  ThunderboltOutlined,
  TeamOutlined,
  PictureOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { useAuth } from '../../auth/AuthContext';
const { Header, Content, Sider } = Layout;
const { Meta } = Card;
const {Title} = Typography;


const Profile = () => {
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {}; 

  return (
    <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
      <Title level={3}>Your Profile</Title>
      <Row gutter={16}>
      <Col span={8}>
          <Card style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} style={{backgroundColor: "#1677FF", marginBottom: "3%"}}>{user.fullname.split(" ")[0][0]}{user.fullname.split(" ")[1][0]}</Avatar>
              
              <Meta
                title={user.fullname}
                description="Hyderabad, India"
              />
            </div>
            
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <Title level={5}>Edit Your Profile</Title><hr/>
            <Form layout="vertical" style={{paddingTop: "3%"}}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Name">
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Email">
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Mobile Number">
                    <Input placeholder="Mobile Number" />
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                  <Form.Item label="Age">
                    <Input placeholder="Age" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Weight">
                    <Input placeholder="Weight" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Height">
                    <Input placeholder="Height" />
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item label="Address">
                    <Input.TextArea placeholder="Address" />
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary">Save Changes</Button>
            </Form>
          </Card>
        </Col>        
      </Row>    
    </Content>
  )
}

export default Profile