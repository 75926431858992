import React from 'react'
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

interface AppFooterProps {
  width?: string;
  rightAlign?: number | string;
}

const AppFooter = ({ width = "90%", rightAlign= 0 }: AppFooterProps) => {
  return (
    // <Footer style={{ textAlign: 'center', position: "fixed", bottom: 0, width: width, backgroundColor: "transparent", right: rightAlign }}>
    <Footer style={{ textAlign: 'center'}}>
        Sporo Health ©{new Date().getFullYear()} All rights reserved.
    </Footer>
  )
}

export default AppFooter;