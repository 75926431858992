import React, { useEffect } from 'react';
import { Button, Flex, message } from 'antd';
import { AudioMutedOutlined, AudioOutlined, DeleteOutlined } from '@ant-design/icons';
import { useReactMediaRecorder } from "react-media-recorder";

interface AudioRecorderProps {
  setAudioUrl: any;
  isRecordDisabled: any;
  audioUrl: any;
}

const AudioRecorder = ({setAudioUrl, isRecordDisabled, audioUrl}: AudioRecorderProps) => {
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl
  } = useReactMediaRecorder({ audio: true });

  useEffect(() => {
    if (mediaBlobUrl) {
      setAudioUrl(mediaBlobUrl);
    }else{
      setAudioUrl(null)
    }
  }, [mediaBlobUrl]);
  
  return (
    <div>
      <div style={{textAlign: "center", marginTop: "30px"}}>
        {status === "recording" ? (
          <Button 
            icon={<AudioMutedOutlined style={{fontSize: "50px"}} />} 
            onClick={()=>{stopRecording(); message.info("Recording stopped!");}}
            type="primary"
            danger 
            style={{height: "100px", width: "100px", borderRadius: "50%", padding: "20px"}}
            disabled={isRecordDisabled} />
        ) : (
          <Button 
            icon={<AudioOutlined style={{fontSize: "50px"}} />} 
            onClick={()=>{startRecording(); message.info("Recording started!");}}
            type="primary" 
            style={{height: "100px", width: "100px", borderRadius: "50%", padding: "20px"}} 
            disabled={isRecordDisabled} />
        )}
      </div>
      <div style={{textAlign: "center", marginTop: "30px"}}>
        {audioUrl && (
          <Flex>
            <audio src={mediaBlobUrl} controls style={{width: "90%"}} /> 
            <Button type='primary' onClick={()=>{setAudioUrl(null)}} icon={<DeleteOutlined />} danger style={{marginTop: "15px", marginLeft: "10px"}} />
          </Flex>
        )}
      </div>
    </div>
  );
};

export default AudioRecorder;
