import React from 'react'
import { Layout, Typography, Row, Col, Card, Tabs } from 'antd';
import DummyTable from '../../DummyComponent/DummyTable/DummyTable';
import HistoryTable from '../HistoryTable/HistoryTable';
const { Header, Content, Sider } = Layout;
const {Title} = Typography;

const PatientHistory = () => {
  return (
    <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
    <Title level={3}>History</Title>
      <Row>
        <Col span={24}>
          <Card>
            <Tabs
              type="card"
              items={[
                {
                  label: "AI Generated Medical Notes",
                  key: "1",
                  children: <HistoryTable historyType="write" />,
                },
                {
                  label: "Medical Notes Analysis",
                  key: "2",
                  children: <HistoryTable historyType="analyze" />,
                },
                {
                  label: "Synopsis (EHR)",
                  key: "3",
                  children: <HistoryTable historyType="synopsis" />,
                },
                {
                  label: "Synopsis (Audio)",
                  key: "4",
                  children: <HistoryTable historyType="synopsis-audio" />,
                }
              ]}
            />
          </Card>
          
        </Col>
      </Row>
    
    </Content>
  )
}

export default PatientHistory;