import React, { useEffect, useState } from 'react';
import { InboxOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Upload, Button, Input, message, Image, Card, Flex, Space, Typography, Row, Col, Checkbox } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../auth/AuthContext';

const { Dragger } = Upload;
const { TextArea } = Input;
const {Title } = Typography;

interface ImageUploaderProps {
  setJsonResponse: any;
  setIsLoading: any;
  setOverallResponse: any;
  setAugmentedResponse: any;
}

const ImageUploader = ({ setJsonResponse, setIsLoading, setOverallResponse, setAugmentedResponse }: ImageUploaderProps) => {
  const auth = useAuth();
  const [text, setText] = useState<string>('');
  const [fileList, setFileList] = useState<any[]>([]);
  const [pdfList, setPdfList] = useState<any[]>([]);
  const [previewImages, setPreviewImages] = useState<Array<{ uid: string; url: string }>>([]);
  const [check, setCheck] = useState<any>();

  const handleFileChange = ({ fileList }: any, type: string) => {
    const newList = fileList.map((file: any) => ({
      ...file,
      status: 'done', // Manually set file status to 'done'
      uid: file.uid,
      name: file.name,
      originFileObj: file.originFileObj ? file.originFileObj : file,
    }));

    if (type === 'image') {
      setFileList(newList);
      const newPreviewImages = newList.map((file: any) => ({
        uid: file.uid,
        url: file.originFileObj ? URL.createObjectURL(file.originFileObj) : file.url,
      }));
      setPreviewImages(newPreviewImages);
    } else if (type === 'pdf') {
      setPdfList(newList);
    }

    setJsonResponse([]);
    setOverallResponse({});
    setAugmentedResponse('')
  };  


  const handleRemove = (uid: string, type: string) => {
    if (type === 'image') {
      const newFileList = fileList.filter(file => file.uid !== uid);
      setFileList(newFileList);
      setPreviewImages(prevImages => prevImages.filter(image => image.uid !== uid));
    } else if (type === 'pdf') {
      const newPdfList = pdfList.filter(file => file.uid !== uid);
      setPdfList(newPdfList);
    }
  };

  const beforeUpload = (file: any, type: string) => {
    if (type === 'image') {
      const isSupportedImageType = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isSupportedImageType) {
        message.error('You can only upload JPG/PNG file!');
      }
      return isSupportedImageType ? true : Upload.LIST_IGNORE;
    } else if (type === 'pdf') {
      const isSupportedPdfType = file.type === 'application/pdf';
      if (!isSupportedPdfType) {
        message.error('You can only upload PDF files!');
      }
      if (pdfList.length >= 2) {
        message.error('You can only upload up to 2 PDF files!');
        return Upload.LIST_IGNORE;
      }
      return isSupportedPdfType ? true : Upload.LIST_IGNORE;
    }
  };

  const customRequest = ({ file, onSuccess }:any) => {
    // Simulate an immediate successful upload
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
  };

  const imageUploadProps = {
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    customRequest: customRequest,
    name: 'file',
    multiple: true,
    fileList,
    onChange: (info:any) => handleFileChange(info, 'image'),
    beforeUpload: (file:any) => beforeUpload(file, 'image'),
    onRemove: (file:any) => {
      handleRemove(file.uid, 'image');
      return true;
    },
    showUploadList: false,
    accept: ".jpg, .png, .jpeg"
  };

  const pdfUploadProps = {
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    customRequest: customRequest,
    name: 'file',
    multiple: true,
    fileList: pdfList,
    onChange: (info:any) => handleFileChange(info, 'pdf'),
    beforeUpload: (file:any) => beforeUpload(file, 'pdf'),
    onRemove: (file:any) => {
      handleRemove(file.uid, 'pdf');
      return true;
    },
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined onClick={(event:any) => handleRemove(event.currentTarget.getAttribute('data-uid'), 'pdf')} />,
    },
    accept: ".pdf",
    maxCount: 2
  };

  const handleSubmit = async () => {
    if (fileList.length === 0 && pdfList.length === 0 && !text.trim()) {
      message.error("Please upload an image, a PDF, or enter chief complaints.");
      return;
    }

    setIsLoading(true);

    // Generate a unique process ID
    const processID = uuidv4();
    let tempQuery = "";
    if (fileList.length > 0){
      let counter = 1;
      const formData = new FormData();
      formData.append('process_id', processID);
      for (let file of fileList) {
        formData.append('image_file', file.originFileObj);
    
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/analyze_image/`, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${auth.user['access_token']}`,
            },
            body: formData
          });
          const data = await response.json();
          setJsonResponse((prev:any) => [...prev, data]);
          tempQuery += "Analysis "+counter+":\n"+data.analysis+"\n";
          counter++;
        } catch (error) {
          console.error('Analyze failed:', error);
          message.error('Analyze failed.');
        }
      }
    }
   
    let resultsFormData = new FormData();
    if (text.trim()) {
      resultsFormData.append('patient_symptoms', text);
    }
    if (fileList.length > 0) {
      resultsFormData.append('image_input', tempQuery);
    }
    pdfList.forEach((file) => {
      resultsFormData.append('pdfs', file.originFileObj);
    });
    resultsFormData.append('process_id', processID);
    try {
      const finalResponse = await fetch(`${process.env.REACT_APP_API_URL}/overall_analysis/`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: resultsFormData // This formData now includes both images and PDFs
      });
      const finalData = await finalResponse.json();
      setOverallResponse(finalData);
    } catch (error) {
      console.error('Final analysis failed:', error);
      message.error('Final analysis failed.');
    }

    setFileList([]);
    setPdfList([]);
    setText('');
    setPreviewImages([]);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Title level={5} style={{marginTop: "0px"}}>Upload Images</Title>
      <Dragger {...imageUploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
        </p>
      </Dragger>
      <Flex style={{ marginTop: 16, overflowX: "auto" }}>
        {previewImages.map(({ uid, url }) => (
          <Space key={uid} direction='vertical' style={{ margin: "10px" }}>
            <Image alt="preview" src={url} style={{ width: '150px', height: "150px", borderRadius: "10px" }} />
            <Button type='primary' style={{ width: "100%" }} danger onClick={() => handleRemove(uid, 'image')}><DeleteOutlined /></Button>
          </Space>
        ))}
      </Flex>
      <Title level={5} style={{marginTop: "20px"}}>Upload Medical Reports</Title>
      <Dragger {...pdfUploadProps}>
        <p className="ant-upload-drag-icon">
          <FilePdfOutlined />
        </p>
        <p className="ant-upload-text">Click or drag PDF to this area to upload</p>
        <p className="ant-upload-hint">
          Support for uploading up to 2 PDF files.
        </p>
      </Dragger>
      <Title level={5} style={{marginTop: "20px"}}>Enter Chief Complaints</Title>
      <TextArea
        rows={4}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Please enter your health notes here..."        
      />

      <Row style={{paddingTop: "20px"}}>
        <Col span={12} style={{ textAlign: "left" }}>
          {/* <Checkbox onChange={(e)=>setCheck(e.target.checked)} disabled={check}>Checkbox</Checkbox> */}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleSubmit}>Generate Medical Note</Button>
        </Col>
      </Row>
      <div >
        
      </div>

    </React.Fragment>
  );
};

export default ImageUploader;
