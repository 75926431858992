import React, { useState } from 'react';
import { Typography, Layout, Upload, Button, Input, Card, message, Row, Col, Divider, Flex } from 'antd';
import { FilePdfOutlined, InboxOutlined, LeftOutlined } from '@ant-design/icons';
import { useAuth } from '../../../auth/AuthContext';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import DownloadDocButton from '../../ButtonComponents/DownloadDocButton';
import DummyFeedback from '../../../DummyComponent/DummyFeedback/DummyFeedback';
import Feedback from '../../Feedback/Feedback';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
const { Content } = Layout;
const {Title} = Typography;
const { Dragger } = Upload;


const AnalyzeMedicalNotes: React.FC = () => {
    const auth = useAuth();
    const [fileList, setFileList] = useState<any[]>([]);
    const [textInput, setTextInput] = useState<string>('');
    const [analysisResult, setAnalysisResult] = useState<any | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isTextAreaDisabled = fileList.length > 0;
    const isUploaderDisabled = textInput.trim().length > 0;

    const markdownToHtml = (markdown: any) => {
        const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
        return DOMPurify.sanitize(rawHtml as string);// Sanitize HTML to prevent XSS attacks
    };

    const handleFileChange = (info: any) => {
        let fileList = [...info.fileList];
        // Filter out non-PDF files if necessary
        fileList = fileList.filter(file => file.type === 'application/pdf' || file.type === '');
        setFileList(fileList);
    };

    const handleTextInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextInput(event.target.value);
    };

    const handleSubmit = async () => {
        if (fileList.length === 0 && textInput.trim() === '') {
            message.warning('Please upload a PDF file or enter some medical notes before submitting.');
            return;
        }
        setIsSubmitting(true);
        // Prepare the data for submission
        const formData = new FormData();
        if (fileList.length > 0) {
        fileList.forEach(file => {
            formData.append('pdfs', file.originFileObj);
        });
        } else {
        formData.append('medical_notes', textInput);
        }

        try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/analyze_medical_notes`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth.user['access_token']}`,
            },
            body: formData,
        });
        const data = await response.json();
        setAnalysisResult(data);
        message.success('Analysis complete!');
        } catch (error) {
        message.error('An error occurred during analysis.');
        console.error('Error:', error);
        }
        setIsSubmitting(false);
    };

    const handleBack = () => {
        setFileList([]);
        setTextInput('');
        setAnalysisResult(null);
    };

    return (
        <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
            <Title level={3}>Analyze Medical Notes</Title>
            <Row gutter={16}>
                <Col span={24}>
                    <Card style={{backgroundColor: "#e5e5e5"}}>
                        {analysisResult ? (
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <LeftOutlined onClick={handleBack} style={{ marginBottom: "20px", marginLeft: "10px", fontSize: '24px', color: '#1677FF', fontWeight: 700}} />
                                    </Col>
                                    <Col span={12} style={{ textAlign: "right", paddingRight: "10px" }}>
                                    <Flex style={{ justifyContent: 'flex-end' }}>
                                        <CopytoClipboard text={analysisResult.analysis}/><DownloadDocButton markdownText={analysisResult.analysis} fileName={"Medical Note Analysis Report"} />
                                    </Flex>
                                        
                                    </Col>
                                </Row>
                                
                                <Card title="Analysis">
                                    <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(analysisResult.analysis) }} style={{maxHeight: "500px", overflowY: "auto"}} />
                                    <Feedback submitionType="analyze" id={analysisResult.id} />
                                </Card>
                            </div>
                        ) : (
                            <div>
                            <Title level={5}>Upload Your Medical Notes</Title>
                            <Dragger
                                multiple
                                beforeUpload={() => false} // Prevent automatic upload
                                onChange={handleFileChange}
                                fileList={fileList}
                                accept=".pdf"
                                disabled={isUploaderDisabled}
                            >
                                <p className="ant-upload-drag-icon">
                                <FilePdfOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag PDF files to this area to upload</p>
                                <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                                </p>
                            </Dragger>
                            <br />
                            <div style={{ textAlign: "center" }}>
                            <Divider>
                                <span style={{ fontSize: '16px', color: '#888' }}>OR</span>
                            </Divider>
                            </div>
                            <Title level={5} style={{marginTop: "20px"}}>Enter Your Medical Notes</Title>
                            <Input.TextArea
                                rows={10}
                                value={textInput}
                                onChange={handleTextInputChange}
                                placeholder="Write your medical notes here..."
                                disabled={isTextAreaDisabled}
                            />
                            <br />
                            <br />
                            <div style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};

export default AnalyzeMedicalNotes;
