// Chatbot.tsx
import React, { useState } from 'react';
import { List, Input, Button, Avatar, Space, Radio, Typography, Collapse, Spin } from 'antd';
import type { RadioChangeEvent } from 'antd';
import CopytoClipboard from '../ButtonComponents/CopytoClipboard';
import { SendOutlined } from '@ant-design/icons';
import {marked} from 'marked'; // Import marked for markdown to HTML conversion
import './Chatbot.css'; // Import custom CSS for additional styling
import DOMPurify from 'dompurify';
import { useAuth } from '../../auth/AuthContext';
import DummyFeedback from '../../DummyComponent/DummyFeedback/DummyFeedback';
import botAvatar from '../../assets/images/bot-avatar.png';
const {Title, Link} = Typography;
const Panel = Collapse.Panel;

interface ChatMessage {
  id: number;
  content: any;
  sender: 'you' | 'bot';
}

const initialMessages: ChatMessage[] = [
  { id: 1, content: "Hello, What can I do for you?", sender: 'bot' },
];

const Chatbot: React.FC = () => {
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {}; 
  const auth = useAuth();
  const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);
  const [newMessage, setNewMessage] = useState<string>('');

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    // User message
    const nextYouMessage: ChatMessage = {
        id: messages.length + 1,
        content: newMessage,
        sender: 'you',
    };

    // Temporary loading message for bot
    const loadingMessage: ChatMessage = {
        id: messages.length + 2,
        content: "Loading bot's response...", // This could be replaced with a spinner or any placeholder
        sender: 'bot',
    };

    // Update state with user message and temporary loading message
    setMessages(messages => [...messages, nextYouMessage, loadingMessage]);
    setNewMessage('');
    try {
        const botResponse = await fetchBotResponse(newMessage);
        
        // Replace the loading message with actual bot response
        setMessages(messages => messages.map(message => 
          message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        ));
      } catch (error) {
        console.error("Fetching bot's response failed:", error);
        // Update loading message with error message
        setMessages(messages => messages.map(message => 
          message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        ));
      }
  };

  const fetchBotResponse = async (query:any) => {
    try{
        let formData = new FormData();
        formData.append("query", query);
        if ( value === 1 ) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/llm_query`, {
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                if(data.error){                    
                    return <div style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}>
                    <div style={{textAlign: "right"}}><CopytoClipboard text={data}/></div><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(data.error) }} />
                    <DummyFeedback/>
                    </div>
                } 
                return <div style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}>
                <div style={{textAlign: "right"}}><CopytoClipboard text={data}/></div>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(data) }} />
                <DummyFeedback/>
                </div>;
            }
        }else if ( value === 2 ) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/pubmed_query`, {
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                if(data.error){
                    return <div style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}>
                    <div style={{textAlign: "right"}}><CopytoClipboard text={data}/></div><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(data.error) }} />
                    <DummyFeedback/>
                    </div>
                }               
                return  <div style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}>
                <div style={{textAlign: "right"}}><CopytoClipboard text={data.text_analysis}/></div>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(data.text_analysis) }} />
                <DummyFeedback/>
                </div>
                ;
            }
        }else if ( value === 3 ) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/arxiv_query`, {
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                if(data.error){
                    return <div style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}>
                    <div style={{textAlign: "right"}}><CopytoClipboard text={data}/></div><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(data.error) }} />
                    <DummyFeedback/>
                    </div>
                }           
                return <Collapse accordion>
                {data.map((response:any, index:any) => (
                  <Panel header={"Response "+String(Number(index)+1)} key={index + 1}>
                    <div style={{textAlign: "right"}}><CopytoClipboard text={response["page content"]}/></div>
                    <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(response["page content"]) }} />
                    <div style={{textAlign: "right", marginBottom: "10px"}}>
                        <Link href={response["entry id"]} target="_blank" rel="noopener noreferrer">
                        Source Citation
                        </Link>
                    </div>
                    <DummyFeedback/>
                  </Panel>
                ))}
              </Collapse>;
            }
        }else if ( value === 4 ) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tavily_search`, {
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                if(data.error){
                    return <div style={{backgroundColor: "#ffffff", padding: "10px", borderRadius: "10px"}}>
                    <div style={{textAlign: "right"}}><CopytoClipboard text={data}/></div><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(data.error) }} />
                    <DummyFeedback/>
                    </div>
                } 
                return <Collapse accordion>
                {data.map((response:any, index:any) => (
                  <Panel header={"Response "+String(Number(index)+1)} key={index + 1}>
                    <div style={{textAlign: "right"}}><CopytoClipboard text={response.synthesized_response}/></div>
                    <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(response.synthesized_response) }} />
                    <div style={{textAlign: "right", marginBottom: "10px"}}>
                        <Link href={response.url} target="_blank" rel="noopener noreferrer">
                        Source Citation
                        </Link>
                    </div>
                    <DummyFeedback/>
                  </Panel>
                ))}
              </Collapse>;
            }
        }
        
    }catch(err){
        return err
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const markdownToHtml = (markdown: any) => {
    const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
    return DOMPurify.sanitize(rawHtml as string);// Sanitize HTML to prevent XSS attacks
  };

  return (
    <>
        <div className="chat-container">
            <List
                className="chat-list"
                dataSource={messages}
                renderItem={item => (
                <List.Item key={item.id} className={`chat-message ${item.sender}`} style={{padding: "10px", borderRadius: "10px"}}>
                    {item.sender === 'bot' && (
                        <Avatar src={botAvatar} style={{backgroundColor: "#000000", padding:"5px"}} className="avatar-left" />
                    )}
                    <List.Item.Meta
                    title={item.sender === 'you' ? 'You' : 'Sporo Health'}
                    description={item.content === "Loading bot's response..." ? <Spin /> : item.content} // Show spinner if loading
                    />
                    
                    {item.sender === 'you' && (
                        <Avatar className="avatar-right" style={{backgroundColor: "#1677FF"}}>{user.fullname.split(" ")[0][0]}{user.fullname.split(" ")[1][0]}</Avatar>
                    )}
                </List.Item>
                )}
            />        
        </div>
        <Space.Compact style={{ width: '100%', marginTop: "20px"}}>
            <Input value={newMessage}
            onChange={handleInputChange}
            onPressEnter={handleSendMessage}
            placeholder="Ask your query..." />
            <Button type="primary" onClick={handleSendMessage} icon={<SendOutlined />}/>
        </Space.Compact>
        <Title level={5}>Select the source</Title>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={1}>LLM</Radio>
          <Radio value={2}>PubMed</Radio>
          <Radio value={3}>Arxiv</Radio>
          <Radio value={4}>Web Search</Radio>
        </Radio.Group>

    </>
  );
};

export default Chatbot;
