import React from 'react';
import { Document, Packer, Paragraph, TextRun  } from 'docx';
import { saveAs } from 'file-saver';
import MarkdownIt from 'markdown-it';
import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { marked } from 'marked';
// import htmlDocx from 'html-docx-js';
import { asBlob } from 'html-docx-js-typescript'
const mdParser = new MarkdownIt();


interface DownloadDocButtonProps {
  markdownText: any;
  fileName?: string;
}

const DownloadDocButton = ({ markdownText, fileName = 'File' }: DownloadDocButtonProps) => {

  const handleDownload = () => {

    const htmlContent:any = marked(markdownText);
    asBlob(htmlContent).then((data:any) => {
      saveAs(data, `${fileName}.docx`) // save as docx file
    })

  };

  return (
    <Tooltip placement="top" title="Download Docx">
      <Button shape="circle" type="primary" icon={<DownloadOutlined />} onClick={handleDownload} style={{backgroundColor: "#00ae5a"}}>
      </Button>
    </Tooltip>
  );
};

export default DownloadDocButton;
