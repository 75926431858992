import React, {useEffect, useState} from 'react'
import { useAuth } from '../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Spin, Empty } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DislikeFilled, EyeOutlined, LikeFilled } from '@ant-design/icons';
import ViewContentModal from './ViewContentModal/ViewContentModal'

interface HistoryTableProps {
    historyType: any;
    location?: string;
}
const HistoryTable = ({historyType, location}: HistoryTableProps) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState<any>({});
    const [dataRecord, setDataRecord] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
  
    const handleOk = () => {
        switch (historyType) {
            case 'write':
                fetchWrittenMedicalNotesDetails();
                break;
            case 'analyze':
                fetchAnalysisDetails();
                break;
            case 'synopsis':
                fetchSynopsisDetails();
                break;
            case 'synopsis-audio':
                fetchSynopsisAudioDetails();
                break;
            default:
                console.error('Invalid historyType');
        }
        setIsModalOpen(false);
    };
  
    const handleCancel = () => {
        switch (historyType) {
            case 'write':
                fetchWrittenMedicalNotesDetails();
                break;
            case 'analyze':
                fetchAnalysisDetails();
                break;
            case 'synopsis':
                fetchSynopsisDetails();
                break;
            case 'synopsis-audio':
                fetchSynopsisAudioDetails();
                break;
            default:
                console.error('Invalid historyType');
        }
        setIsModalOpen(false);
    };

    function removeMarkdown(text: string): string {
        return text.replace(/[\#\*\-\[\]\!\`]/g, ' ').replace(/\s\s+/g, ' ');
    }

    function truncateText(text: string, limit: number = 20): string {
        try {
            const cleanedText = removeMarkdown(text)
            const words = cleanedText.split(' '); // Split text into words
            if (words.length > limit) {
                return words.slice(0, limit).join(' ') + '...'; // Join the first 10 words and append '...'
            }
            return cleanedText;
        }catch (error){
            return ""
        }
    }
    const fetchWrittenMedicalNotesDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch_written_medical_notes`, {
                method: 'GET',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                
                const transformedData = data.map((item:any) => ({
                    key: item[0], // Assuming the first item is a unique identifier
                    username: item[1],
                    processId: item[2],
                    patientSymptoms: truncateText(item[3]),
                    patientSymptomsFull: item[3],
                    diagnosticInput: truncateText(item[4]), 
                    patientEHRDetails: truncateText(item[5]),
                    patientEHRDetailsFull: item[5],
                    aiGeneratedAnalysisResult: truncateText(item[6]), 
                    aiGeneratedAnalysisResultFull: item[6], 
                    augmentedAnalysisResult: truncateText(item[7]), 
                    augmentedAnalysisResultFull: item[7], 
                    feedback: item[8] !== null ? item[8] === "up" ? <LikeFilled style={{marginRight: "10px",color: 'white', 
                    backgroundColor: "green", padding: "10px", borderRadius: "50%", fontSize: "16px"}} /> : <DislikeFilled style={{marginRight: "10px",color: 'white', 
                    backgroundColor: "red", padding: "10px", borderRadius: "50%", fontSize: "16px" }} /> : "",
                    feedbackFull: item[8] || "",
                    comment: item[9] || "",
                    dateCreated: item[10],
                }));
                setDataSource(transformedData)
            }else{
                setDataSource(null);
            }
            
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const fetchAnalysisDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch_analysis_result`, {
                method: 'GET',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                const transformedData = data.map((item:any) => ({
                    key: item[0], // Assuming the first item is a unique identifier
                    username: item[1],
                    medicalNotes: truncateText(item[2]),
                    medicalNotesFull: item[2],
                    analysisReport: truncateText(item[3]),
                    analysisReportFull: item[3],
                    feedback: item[4] !== null ? item[4] === "up" ? <LikeFilled style={{marginRight: "10px",color: 'white', backgroundColor: "green", padding: "10px", borderRadius: "50%", fontSize: "16px"}} /> : <DislikeFilled style={{marginRight: "10px",color: 'white', 
                    backgroundColor: "red", padding: "10px", borderRadius: "50%", fontSize: "16px" }} /> : "",
                    feedbackFull: item[4] || "",
                    comment: item[5] || "",
                    dateCreated: item[6],
                }));
                setDataSource(transformedData)
            }else{
                setDataSource(null);
            }
            
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const fetchSynopsisDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch_synopsis_result`, {
                method: 'GET',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                const transformedData = data.map((item:any) => ({
                    key: item[0], // Assuming the first item is a unique identifier
                    username: item[1],
                    patientHistory: item[2] === null? "Pateint Details Fetched from EHR" : truncateText(item[2]),
                    patientHistoryFull: item[2],
                    patientEHRDetails: item[3],
                    synopsis: truncateText(item[4]),
                    synopsisFull: item[4],
                    feedback: item[10] !== null ? item[10] === "up" ? <LikeFilled style={{marginRight: "10px",color: 'white', backgroundColor: "green", padding: "10px", borderRadius: "50%", fontSize: "16px"}} /> : <DislikeFilled style={{marginRight: "10px",color: 'white', 
                    backgroundColor: "red", padding: "10px", borderRadius: "50%", fontSize: "16px" }} /> : "",
                    feedbackFull: item[10] || "",
                    comment: item[11] || "",
                    dateCreated: item[12],
                }));
                setDataSource(transformedData)
            }else{
                setDataSource(null);
            }
            
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };
    const fetchSynopsisAudioDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch_synopsis_audio_result`, {
                method: 'GET',
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                const transformedData = data.map((item:any) => ({
                    key: item[0], // Assuming the first item is a unique identifier
                    username: item[1],
                    audioUrl: item[2],
                    audioTranscription: truncateText(item[3]) || "",
                    audioTranscriptionFull: item[3],
                    synopsis: truncateText(item[4]),
                    synopsisFull: item[4],
                    feedback: item[10] !== null ? item[10] === "up" ? <LikeFilled style={{marginRight: "10px",color: 'white', backgroundColor: "green", padding: "10px", borderRadius: "50%", fontSize: "16px"}} /> : <DislikeFilled style={{marginRight: "10px",color: 'white', 
                    backgroundColor: "red", padding: "10px", borderRadius: "50%", fontSize: "16px" }} /> : "",
                    feedbackFull: item[10] || "",
                    comment: item[11] || "",
                    dateCreated: item[12],
                }));
                setDataSource(transformedData)
            }else{
                setDataSource(null);
            }
            
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };
    useEffect(() => {   
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        // Fetch data based on historyType
        switch (historyType) {
            case 'write':
                await fetchWrittenMedicalNotesDetails();
                break;
            case 'analyze':
                await fetchAnalysisDetails();
                break;
            case 'synopsis':
                await fetchSynopsisDetails();
                break;
            case 'synopsis-audio':
                await fetchSynopsisAudioDetails();
                break;
            default:
                console.error('Invalid historyType');
        }
        setIsLoading(false);
    };

    useEffect(() =>{
        if (!auth.user) {
            auth.logout();
            navigate('/login');
        }
    }, [auth.user])

    let columns: ColumnsType<any> = [];
    let modalTitle: string = "";
    switch (historyType) {
        case 'write':
            modalTitle="AI Generated Medical Note";
            columns = [
            // columns specific to 'write'
            { title: 'Date Created', dataIndex: 'dateCreated', key: 'dateCreated', width: 150 },
            { title: 'Chief Complaints', dataIndex: 'patientSymptoms', key: 'patientSymptoms' },
            { title: 'Patient Details from EHR', dataIndex: 'patientEHRDetails', key: 'patientEHRDetails' },
            { title: 'AI Generated Analysis Result', dataIndex: 'aiGeneratedAnalysisResult', key: 'aiGeneratedAnalysisResult' },
            { title: 'Augmented Analysis Result', dataIndex: 'augmentedAnalysisResult', key: 'augmentedAnalysisResult' },
            { title: 'Feedback', dataIndex: 'feedback', key: 'feedback' },
            { title: 'Comment', dataIndex: 'comment', key: 'comment' },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                <Button shape="circle" type="default" icon={<EyeOutlined />} onClick={() => {setDataRecord({ ...record, dataType: "write" }); showModal() }} />
                ),
            },
            ];
            break;
        case 'analyze':
            modalTitle="Medical Notes Analysis";
            columns = [
            // columns specific to 'analyze'
            { title: 'Date Created', dataIndex: 'dateCreated', key: 'dateCreated', width: 150 },
            { title: 'Medical Notes', dataIndex: 'medicalNotes', key: 'medicalNotes' },
            { title: 'Analysis Report', dataIndex: 'analysisReport', key: 'analysisReport' },
            { title: 'Feedback', dataIndex: 'feedback', key: 'feedback' },
            { title: 'Comment', dataIndex: 'comment', key: 'comment' },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                <Button shape="circle" type="default" icon={<EyeOutlined />} onClick={() => { setDataRecord({ ...record, dataType: "analyze" }); showModal() }} />
                ),
            },
            ];
            break;
        case 'synopsis':
            modalTitle="Synopsis from EHR";
            columns = [
            // columns specific to 'analyze'
            { title: 'Date Created', dataIndex: 'dateCreated', key: 'dateCreated', width: 150 },
            { title: 'Patient History', dataIndex: 'patientHistory', key: 'patientHistory' },
            { title: 'Synopsis', dataIndex: 'synopsis', key: 'synopsis' },
            { title: 'Feedback', dataIndex: 'feedback', key: 'feedback' },
            { title: 'Comment', dataIndex: 'comment', key: 'comment' },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                <Button shape="circle" type="default" icon={<EyeOutlined />} onClick={() => { setDataRecord({ ...record, dataType: "synopsis" }); showModal() }} />
                ),
            },
            ];
            break;
        case 'synopsis-audio':
            modalTitle="Synopsis from Audio";
            columns = [
            // columns specific to 'analyze'
            { title: 'Date Created', dataIndex: 'dateCreated', key: 'dateCreated', width: 150 },
            { title: 'Audio Transcription', dataIndex: 'audioTranscription', key: 'audioTranscription' },
            { title: 'Synopsis', dataIndex: 'synopsis', key: 'synopsis' },
            { title: 'Feedback', dataIndex: 'feedback', key: 'feedback' },
            { title: 'Comment', dataIndex: 'comment', key: 'comment' },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                <Button shape="circle" type="default" icon={<EyeOutlined />} onClick={() => { setDataRecord({ ...record, dataType: "synopsis-audio" }); showModal() }} />
                ),
            },
            ];
            break;
        default:
            modalTitle="";
            columns = [];
    }
    const adminColumn = {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
    };
    const userDetails = localStorage.getItem("userDetails");
    const user = userDetails ? JSON.parse(userDetails) : {}; 
    const userRole = user.role;
    if (userRole === 'admin') {
        // Find the index of the 'Date Created' column
        const dateCreatedIndex = columns.findIndex((column:any) => column.dataIndex === 'dateCreated');
      
        // Insert the 'Username' column just after 'Date Created'
        // If 'Date Created' is found, insert after it; otherwise, append at the end
        const insertIndex = dateCreatedIndex >= 0 ? dateCreatedIndex + 1 : columns.length;
        columns.splice(insertIndex, 0, adminColumn);
    }
    
  return (
    <>
    {isLoading ? (
        <div style={{ textAlign: "center", padding: "30px" }}>
            <Spin tip="Loading" size="large" />
        </div>
    ) : dataSource.length > 0 ? (
        <Table
            dataSource={location === "/overview" ? dataSource.slice(0, 5) : dataSource}
            columns={columns}
            pagination={{ pageSize: 5 }}
            className='scrollY'
            style={location === "/overview" ? { maxHeight: "360px" } : {}}
        />
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )}
    <ViewContentModal modalTitle={modalTitle} dataRecord={dataRecord} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} ></ViewContentModal>
    </>
  )
}

export default HistoryTable