// src/App.tsx
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import Login from './components/Login/Login';
import LayoutWithLeftMenu from './components/LayoutWithLeftMenu/LayoutWithLeftMenu';
import Dashboard from './components/Dashboard/Dashboard';
import PatientHistory from './components/PatientHistory/PatientHistory';
import QA from './components/QA/QA';
import Profile from './components/Profile/Profile';
import WriteMedicalNotes from './components/MedicalNotes/WriteMedicalNotes/WriteMedicalNotes';
import AnalyzeMedicalNotes from './components/MedicalNotes/AnalyzeMedicalNotes/AnalyzeMedicalNotes';
import SysnopsisAudio from './components/Sysnopsis/SynopsisAudio/SynopsisAudio';
import SysnopsisText from './components/Sysnopsis/SynopsisText/SynopsisText';
import "./App.css";
import {useAuth} from "./auth/AuthContext";

function App() {
  const auth = useAuth();
  return (
    <div>
        <Routes>
            <Route path="/login" element={<Login />}  />
            <Route element={<ProtectedRoute><LayoutWithLeftMenu /></ProtectedRoute>}>
                <Route path="/overview" element={<Dashboard />} />
                <Route path="/history" element={<PatientHistory />} />
                <Route path="/qa" element={<QA />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/medical-notes-write" element={<WriteMedicalNotes />} />
                <Route path="/medical-notes-analyze" element={<AnalyzeMedicalNotes />} />
                <Route path="/synopsis-from-text" element={<SysnopsisText />} />
                <Route path="/synopsis-from-audio" element={<SysnopsisAudio />} />
                {/* Add more protected routes here */}
            </Route>
            <Route path="/" element={auth.user ? <Navigate to="/overview" /> : <Login />} />
        </Routes>
    </div>
  );
}

export default App;
