import { CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import React from 'react'

interface CopytoClipboardProps {
    text: string;
}

const CopytoClipboard = ({text}: CopytoClipboardProps) => {
    const copyToClipboard = async () => {
        try {
            // Convert the response object to a string format if necessary
            const responseText = text;
            await navigator.clipboard.writeText(responseText);
            message.success('Copied!');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
  return (
    <Tooltip placement="top" title="Copy to Clipboard">
        <Button type="primary" shape="circle" onClick={copyToClipboard} style={{marginRight: "20px"}}><CopyOutlined /></Button>
    </Tooltip>
  )
}

export default CopytoClipboard