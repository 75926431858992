import React, { useState } from 'react';
import { Button, Input, Row, message, Col, Alert } from 'antd';
import { useAuth } from '../../auth/AuthContext';
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';

const { TextArea } = Input;

interface FeedbackProps {
  submitionType: string; // Include feedback type in callback
  id: string; // Unique identifier for each feedback instance
}

const Feedback: React.FC<FeedbackProps> = ({ submitionType, id }) => {
  const auth = useAuth();
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState<'up' | 'down' | null>(null);
  const [feedbackTypeSubmitted, setfeedbackTypeSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async() => {
    if (!feedbackType) {
      message.error('Please select Thumbs Up or Thumbs Down.');
      return;
    }
    let formData = new FormData();
    formData.append('process_id', id);
    formData.append('submition_type', submitionType);
    formData.append('feedback_type', feedbackType);
    if(!feedback) formData.append('feedback', ' ');
    else formData.append('feedback', feedback);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/submit_feedback/`, {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
        });
        if (response.ok) {
            setSubmitted(true);
        }
        
    } catch (error) {
        message.error("Error fetching user details:"+error);
        console.error("Error fetching user details:", error);
    }
    
  };

  return (
        <Row>
            <Col span={12}></Col>
            <Col span={12}>
            {submitted?<Alert message="Feedback submitted successfully." type="success" showIcon closable />:
                <div style={{textAlign: "right"}}>
                    <div style={{marginBottom: "15px"}}>
                        <Button 
                            shape="circle"
                            type={feedbackType === 'up' ? 'primary' : 'default'} 
                            onClick={() => {setFeedbackType('up'); setfeedbackTypeSubmitted(true);}} 
                            icon={<LikeOutlined />}
                            style={{marginRight: "10px",color: feedbackType === 'up' ? 'white' : undefined, 
                            backgroundColor: feedbackType === 'up' ? 'green' : undefined }}
                        />
                        <Button 
                            shape="circle"
                            type={feedbackType === 'down' ? 'primary' : 'default'} 
                            onClick={() => {setFeedbackType('down'); setfeedbackTypeSubmitted(true);}}
                            icon={<DislikeOutlined />}
                            style={{ 
                                color: feedbackType === 'down' ? 'white' : undefined, 
                                backgroundColor: feedbackType === 'down' ? 'red' : undefined 
                              }}
                        />
                    </div>
                    
                    {feedbackTypeSubmitted?
                    <><TextArea rows={2} value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder="Enter your feedback..." />
                    <small style={{color: "red", fontWeight: 600, fontStyle: "italic"}}>Once submitted, you can't edit it back.</small></>: ""}
                    <div style={{textAlign: "right", marginTop: "15px"}}>
                        <Button style={feedbackTypeSubmitted?{display:"inline"}:{display: "none"}} type="primary" onClick={handleSubmit} disabled={!feedbackType}>Submit</Button>
                    </div>
                    
                </div>}
            </Col>
        </Row>
      
  );
};

export default Feedback;
