import React from 'react'
import { Layout, Row, Col, Card, Typography } from 'antd';
import Chatbot from "../Chatbot/Chatbot"
const { Header, Content, Sider } = Layout;
const {Title} = Typography;

const QA = () => {
  return (
    <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
    <Title level={3}>Query Engine</Title>
      <Row>
        <Col span={24}>
          <Card>
            <Chatbot></Chatbot>
          </Card>
        </Col>
      </Row>
    
    </Content>
  )
}

export default QA