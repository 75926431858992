// src/pages/Dashboard.tsx
import React, {useState, useEffect} from 'react';
import { Button, Layout, Row, Col, Card, Spin, List, Avatar, Carousel, Typography, Tabs } from 'antd';
import DummyTable from '../../DummyComponent/DummyTable/DummyTable';
import DummyProduct from '../../DummyComponent/DummyProduct/DummyProduct';
import Sidebar from '../Sidebar/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import HistoryTable from '../HistoryTable/HistoryTable';
const { Header, Content, Sider } = Layout;
const {Title} = Typography;

const contentStyle: React.CSSProperties = {
  width: '100%',
  height: '150px',
  color: '#1677FF',
  textAlign: 'center',
  paddingTop: '40px',
  fontStyle: 'italic',
  // background: '#e5e5e5',
};

const Dashboard = () => {  
  const auth = useAuth();
  const location = useLocation();
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {}; 
  const [count, setCount] = useState<any>({})
  const fetchCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch_count`, {
          method: 'GET',
          headers: { 
              'Authorization': `Bearer ${auth.user['access_token']}`,
          }
      });
      if (response.ok) {
          const data = await response.json();          
          setCount(data)
      }else{
          setCount({});
      }
      
    } catch (error) {
        console.error("Error fetching user details:", error);
    }
  }
  useEffect(() => {   
    fetchCount();
  }, []);

  const getGreeting = () => {
      const hour = new Date().getHours(); // Get the current hour

      if (hour < 12) {
          return "Good morning";
      } else if (hour < 18) {
          return "Good afternoon";
      } else {
          return "Good evening";
      }
  };
  const data = [
    {
      title: "Boehringer Ingelheim's Obesity and Fatty Liver Drug Candidate",
      description: "German pharmaceutical company Boehringer Ingelheim, in collaboration with Zealand Pharma, is developing a drug candidate named survodutide, aimed at treating obesity and fatty liver disease, with potential launch targeted for 2027 or 2028, pending favorable trial outcomes​",
      link: "https://www.reuters.com/business/healthcare-pharmaceuticals/boehringer-eyes-obesity-fatty-liver-drug-launch-2027-or-2028-2024-02-26/"
    },
    {
      title: "Dengue Fever Vaccine Rush in Brazil",
      description: "Brazil is accelerating the rollout of a dengue fever vaccine in response to an unprecedented surge in cases fueled by climate change, marking a significant development in the fight against this mosquito-borne disease​",
      link: "https://www.bmj.com/content/384/bmj.q483"
    },
    {
      title: "South Korea's Junior Doctors Strike",
      description: "In South Korea, junior doctors are striking, faced with threats of arrest and suspension, highlighting significant labor issues within the healthcare system and the global concern of healthcare worker burnout and fair work conditions",
      link: "https://www.bmj.com/content/384/bmj.q495"
    },
    {
      title: "Advances in diagnosis and treatment of bladder cancer",
      description: "Bladder cancer remains a leading cause of cancer death worldwide and is associated with substantial impacts on patient quality of life, morbidity, mortality, and cost to the healthcare system. Gross hematuria frequently precedes the diagnosis of bladder cancer​",
      link: "https://www.bmj.com/content/384/bmj-2023-076743"
    },
    {
      title: "Sponge-on-a-String Test for Reflux Patients",
      description: "A new 'sponge-on-a-string' test has been developed to replace the need for endoscopy in eight out of ten patients with reflux, representing a significant advancement in non-invasive diagnostic procedures. This could change the approach to diagnosing and managing conditions related to gastroesophageal reflux disease (GERD)​",
      link: "https://www.bmj.com/content/384/bmj.q501"
    }
  ];
  return (
    <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
      <Title level={3} style={{marginTop: 0, marginBottom: 0}}>Overview</Title>
      <Title level={5} style={{marginTop: 0}}>Hi, <b style={{color: "#1677FF"}}>{user.fullname}</b>. {getGreeting()}.</Title>

      <Row>
        <Col span={6} style={{padding: "10px", paddingLeft: 0}}>
          <Card>
            <Title level={4} style={{marginTop: 0}}>Total Medical Notes Generated</Title>
            <Title level={1} style={{color: "#6BBAF4", marginTop: 0, marginBottom: 0, fontSize: "40px"}}>{count.generatedMedicalNotes}</Title>
          </Card>          
        </Col>
        <Col span={6} style={{padding: "10px"}}>
          <Card>
            <Title level={4} style={{marginTop: 0}}>Total Medical Notes Analyzed</Title>
            <Title level={1} style={{color: "#6AA6F3", marginTop: 0, marginBottom: 0, fontSize: "40px"}}>{count.analyzedMedicalNotes}</Title>
          </Card>          
        </Col>
        <Col span={6} style={{padding: "10px"}}>
          <Card>
            <Title level={4} style={{marginTop: 0}}>Total Synopsis Generated</Title>
            <Title level={1} style={{color: "#688FF2", marginTop: 0, marginBottom: 0, fontSize: "40px"}}>{count.synopsis}</Title>
          </Card>          
        </Col>
        <Col span={6} style={{padding: "10px", paddingRight: 0}}>
          <Card>
            <Title level={4} style={{marginTop: 0}}>Total Search</Title>
            <Title level={1} style={{color: "#6779F1", marginTop: 0, marginBottom: 0, fontSize: "40px"}}>{count.search}</Title>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={8} style={{paddingTop: "20px", paddingRight: "10px"}}>
          <Card title="Top Health Related News" >
            <List
              style={{maxHeight: "415px", overflowY: "auto"}}
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                    title={<a href={item.link} target='_blank' rel='noreferrer'>{item.title}</a>}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={16} style={{paddingTop: "20px", paddingLeft: "10px"}}>
            <Card title="Your Recent Records">
              <Tabs
                type="card"
                items={[
                  {
                    label: "Medical Notes Analysis",
                    key: "1",
                    children: <HistoryTable historyType="analyze" location={location.pathname} />,
                  },
                  {
                    label: "Synopsis (EHR)",
                    key: "2",
                    children: <HistoryTable historyType="synopsis" location={location.pathname} />,
                  }
                ]}
              />
              
            </Card>
        </Col>
      </Row>
    </Content>
    
  );
};

export default Dashboard;
