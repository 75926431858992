import React, {useState, useEffect} from 'react'
import { Layout, Row, Col, Typography, Card, Collapse, Spin, Flex, Divider, Select, Button, message, Checkbox } from 'antd';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import  ImageUploader  from '../../ImageUploader/ImageUploader';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import EditButton from '../../ButtonComponents/EditButton';
import DownloadDocButton from '../../ButtonComponents/DownloadDocButton';
import Feedback from '../../Feedback/Feedback';
import { useAuth } from '../../../auth/AuthContext';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import DiffViewer from '../../DiffViewer/DiffViewer';
import StickyChat from '../../StickyChat/StickyChat';
const { Content } = Layout;
const {Title} = Typography;
const Panel = Collapse.Panel;

const Analytics = () => {
  const auth = useAuth();
  const [jsonResponse, setJsonResponse] = useState([]);
  const [overallResponse, setOverallResponse] = useState<any>({});
  const [augmentedResponse, setAugmentedResponse] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [patientID, setPatientID] = useState<any>(null);
  const [check, setCheck] = useState<any>();
  useEffect(() => {
    setOptions([
      {label: "5678-1234-ABCD", value: "5678-1234-ABCD"},
      {label: "9876-4321-ZYXW", value: "9876-4321-ZYXW"}
    ])
  }, []);
  const markdownToHtml = (markdown: any) => {
    const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
    return DOMPurify.sanitize(rawHtml as string);// Sanitize HTML to prevent XSS attacks
  };

  const handleCheck = async (checked:any) => {
    if (!patientID) {
      message.error("Please select a Patient ID.");
      return;
    }
    setCheck(checked);
  }

  const handleSubmit = async () => {
    if (!patientID) {
      message.error("Please select a Patient ID.");
      return;
    }

    setIsLoading(true);

    const processID = uuidv4();
    const formData = new FormData();
    formData.append('patient_id', patientID); 
    formData.append('process_id', processID);   
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/analyze_patient_details/`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
      });
      const data = await response.json();
      setOverallResponse(data);
    } catch (error) {
      console.error('Analyze failed:', error);
      message.error('Analyze failed.');
    }    
    setIsLoading(false);
  };

  return (
    <Content style={{ margin: '24px 30px 0', overflow: 'initial' }} className="site-layout-background">
    <Title level={3}>Write Medical Notes</Title>
      <Row gutter={16}>
        <Col span={8}>
          <Card className='max-height scrollY' style={{backgroundColor: "#e5e5e5"}}>
            {!isUpload?
              <>
              <Title level={5} style={{marginTop: "0px"}}>Select a Patient ID</Title>
              <Select
                placeholder="Select a Patient ID"
                allowClear
                style={{width: "100%"}}
                options={options}
                onChange={(value)=> {setPatientID(value); setCheck(false);}}
                value={patientID}
              />
              <Row style={{paddingTop: "20px"}}>
                <Col span={12} style={{ textAlign: "left" }}>
                  {/* <Checkbox checked={check} onChange={(e)=>handleCheck(e.target.checked)} disabled={check}>Checkbox</Checkbox> */}
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button type="primary" onClick={handleSubmit}>Generate Medical Note</Button>
                </Col>
              </Row>
              <br />
              <br />
              
              <div style={{ textAlign: "center" }}>
              <Divider>
                  <span style={{ fontSize: '16px', color: '#888' }}>OR</span>
              </Divider>
              </div>
              <br />
              <br />
              <Button size={"large"} icon={<UploadOutlined />} type="primary" onClick={()=>{setIsUpload(true);setOverallResponse({});}} style={{width: "100%"}}>Upload Pateint Details</Button>
              </>
            :
            <>
              <Row>
                <Col span={12}>
                    <LeftOutlined onClick={()=>{setIsUpload(false);setOverallResponse({});}} style={{ marginBottom: "20px", marginLeft: "10px", fontSize: '24px', color: '#1677FF', fontWeight: 700}} />
                </Col>
              </Row>
              <ImageUploader setJsonResponse={setJsonResponse} setIsLoading={setIsLoading} setOverallResponse={setOverallResponse} setAugmentedResponse={setAugmentedResponse} />
            </>
            }
          </Card>
        </Col>
        <Col span={16}>
          <Card className='max-height scrollY' style={{backgroundColor: "#fff"}}>
            {jsonResponse.length>0?<Collapse accordion>
                {jsonResponse.map((response:any, index:any) => (
                  <Panel header={"Analysis for image "+String(Number(index)+1)} key={index + 1}>
                    <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={response.analysis}/><DownloadDocButton markdownText={response.analysis} fileName={`Image ${String(Number(index)+1)} Analysis report`} /></Flex>
                    <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(response.analysis) }} />
                    <Feedback submitionType="single" id={response.process_id} />
                  </Panel>
                ))}
              </Collapse>:""}
              {Object.keys(overallResponse).length>0?
              <Collapse accordion style={{ marginTop: "30px" }}>                
                <Panel header="AI Generated Medical Notes" key="01">
                  <Flex style={{ justifyContent: 'flex-end' }}><EditButton id={overallResponse.process_id} editType={"write"} text={overallResponse.analysis} setAugmentedResponse={setAugmentedResponse} /><CopytoClipboard text={overallResponse.analysis}/><DownloadDocButton markdownText={overallResponse.analysis} fileName={"AI Generated Medical Notes"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(overallResponse.analysis) }} /><Feedback submitionType="overall" id={overallResponse.process_id} />
                </Panel>
                
                {augmentedResponse.trim().length>0?
                <Panel header="Final Medical Notes" key="02">
                  <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={augmentedResponse}/><DownloadDocButton markdownText={augmentedResponse} fileName={"Final Medical Notes"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(augmentedResponse) }} />
                </Panel>
                :""}
              </Collapse>
              :""}
            {isLoading? 
            <div style={{textAlign: "center", padding: "30px"}}>
              <Spin tip="Loading" size="large" />
            </div>: ""}
          </Card>
        </Col>
      </Row>
      {Object.keys(overallResponse).length>0
      &&
      <StickyChat context={overallResponse.analysis} title="Chat with Medical Note"/>}
    </Content>
  )
}

export default Analytics