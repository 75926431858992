// src/auth/AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import {message} from 'antd';

interface AuthContextType {
  user: any; // Consider using a more specific type for your application
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<null | object>(JSON.parse(localStorage.getItem('user') || 'null'));

  const login = async (username: string, password: string) => {
    try {
        const formBody = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accept': 'application/json'
            },
            body: formBody,
        });

        if (!response.ok) {
            message.error('Login failed');
            throw new Error('Login failed');
        }

        const data = await response.json();
        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
    } catch (error) {
        message.error("Error logging in:"+ error);
        console.error("Error logging in:", error);
        throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  const value = { user, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    message.error('useAuth must be used within an AuthProvider');
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
