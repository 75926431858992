import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {
  UserOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LogoutOutlined,
  DashboardOutlined,
  MessageOutlined,
  UnorderedListOutlined,
  DeploymentUnitOutlined,
  TeamOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import { Layout, Menu, Typography, Avatar, Button, Modal } from 'antd';
import type { MenuProps } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import { useNavigate, useLocation  } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import logo from "../../assets/images/logo-no-background.png"
import favicon from "../../assets/images/favicon.png"

const { Header, Content, Sider } = Layout;

const { Title } = Typography;

interface SidebarProps {
  collapsed: any;
  setCollapsed: any;
}

const Sidebar = ({collapsed, setCollapsed}:SidebarProps) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getSelectedKey = (pathname: string) => {
      // Map of pathnames to menu item keys
      const keyMap: { [key: string]: string } = {
          '/overview': '1',
          '/qa': '2',
          '/history': '3',
          '/medical-notes-write': '4-1',
          '/medical-notes-analyze': '4-2',
          '/synopsis-from-text': '5-1',
          '/synopsis-from-audio': '5-2',
          '/profile': '6',
          // Add other routes and their corresponding keys as needed
      };

      // Return the corresponding key or a default value
      return keyMap[pathname] || '1';
    };  

    const handleLogout = () => {
      auth.logout();
      navigate('/login'); // Navigate to login page after logout
    };

    // Define the menu items with icons and TypeScript typings
    const menuItems: MenuProps['items'] = [
      { key: '1', icon: <DashboardOutlined />, label: <Link to="/overview">Overview</Link> },
      { key: '2', icon: <MessageOutlined />, label: <Link to="/qa">Query Engine</Link> },
      { key: '3', icon: <UnorderedListOutlined />, label: <Link to="/history">History</Link> },
      {
        key: '4',
        icon: <DeploymentUnitOutlined />,
        label: 'Medical Notes',
        children: [
          { key: '4-1', label: <Link to="/medical-notes-write">Write</Link> },
          { key: '4-2', label: <Link to="/medical-notes-analyze">Analyze</Link> },
        ],
      },
      // { key: '5', icon: <FileSearchOutlined />, label: <Link to="/synopsis">Synopsis</Link> },
      {
        key: '5',
        icon: <FileSearchOutlined />,
        label: 'Synopsis',
        children: [
          { key: '5-1', label: <Link to="/synopsis-from-text">From EHR</Link> },
          { key: '5-2', label: <Link to="/synopsis-from-audio">From Audio</Link> },
        ],
      },
      { key: '6', icon: <UserOutlined />, label: <Link to="/profile">Profile</Link> },
      { key: '7', icon: <LogoutOutlined />, label: <Link to="" onClick={handleLogout}>Logout</Link> },
    ];

    
    return (
      <>
      <Sider
          trigger={null} 
          collapsible 
          collapsed={collapsed}
          breakpoint="lg"
          style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          backgroundColor: '#001529'
          }}
      >
          {collapsed ?<div style={{textAlign: "center"}}><img src={favicon} alt="" style={{margin: "20px 10px", width: "50px", height: "auto"}}/></div>:
          <div style={{ textAlign: "center", padding: "20px 10px"}}><img src={logo} alt="" width="80%"/></div>
          }
          <Menu theme="dark" defaultSelectedKeys={[getSelectedKey(location.pathname)]} mode="inline" items={menuItems} />
          {collapsed?<Button type="default" onClick={showModal} style={{position: "absolute", bottom: 60, width: "80%",color: "#1677FF", cursor: "pointer", backgroundColor: "transparent", borderColor: "#1677ff", marginLeft: "10px"}}><TeamOutlined /></Button>:
          <Button type="default" onClick={showModal} style={{position: "absolute", bottom: 60, width: "90%",color: "#1677FF", cursor: "pointer", backgroundColor: "transparent", borderColor: "#1677ff", marginLeft: "10px"}}><TeamOutlined /> Support</Button>}
          <div style={{ position: "absolute", bottom: 20, width: "100%", textAlign: "center" }}  onClick={() => setCollapsed(!collapsed)}>            
            {collapsed? <DoubleRightOutlined style={{color: "#fff", cursor: "pointer"}} /> : <DoubleLeftOutlined style={{color: "#fff", zIndex: 1, cursor: "pointer"}} />}
          </div>
      </Sider>
      <Modal title="Contact Support" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered maskClosable={false} keyboard={false}>
        <div style={{textAlign: "center"}}>
          <Title level={3} style={{padding: "50px 10px"}}>Coming Soon...</Title>
        </div>
      </Modal>
      </>
    )
}

export default Sidebar;
