import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Card, Typography, Collapse, Flex, Space, Image, Alert } from 'antd';
import DownloadDocButton from '../../ButtonComponents/DownloadDocButton';
import Feedback from '../../Feedback/Feedback';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import { useAuth } from '../../../auth/AuthContext';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
const {Title } = Typography;
const Panel = Collapse.Panel;

interface ViewContentModalProps {
  dataRecord: any;
  isModalOpen: any;
  // showModal: any;
  handleOk: any;
  handleCancel: any;
  modalTitle: string;
}

const ViewContentModal = ({dataRecord, isModalOpen, handleOk, handleCancel, modalTitle }: ViewContentModalProps) => {
    const [imageData, setImageData] = useState<any>({});
    const [imageAnalysisData, setImageAnalysisData] = useState<any>({});
    const auth:any = useAuth();
  
    const fetchImageDetails = async () => {
      try {
          const formData = new FormData();
          formData.append("process_id", dataRecord.processId)
          const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch_image_analysis`, {
              method: 'POST',
              headers: { 
                  'Authorization': `Bearer ${auth.user['access_token']}`,
              },
              body: formData
          });
          if (response.ok) {
              const data = await response.json();     
              setImageData(data.image_data);
              setImageAnalysisData(data.image_analysis_result);
          }else{
              setImageData({});
              setImageAnalysisData({});
          }
          
      } catch (error) {
          console.error("Error fetching user details:", error);
      }
    };

    
    useEffect(() => { 
      if(dataRecord.dataType === "write")   
        fetchImageDetails();        
    }, [dataRecord]);
    const markdownToHtml = (markdown: any) => {
      const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
      return DOMPurify.sanitize(rawHtml as string);// Sanitize HTML to prevent XSS attacks
    };
    const getModalUI = () =>{
      switch(dataRecord.dataType) {
        case "write":
          return (<Row>
            <Col span={8} style={{padding: "10px"}}>
                {imageData.length > 0 &&    
                  <Card title="Uploaded Images" style={{marginBottom: "20px"}}>                        
                      <Flex style={{ marginTop: 16, overflowX: "auto" }}>
                        {imageData.map((image:any) => (
                          <Space direction='vertical' style={{ margin: "10px" }}>
                            <Image alt="preview" src={`data:image/jpeg;base64,${image}`} style={{ width: '150px', height: "150px", borderRadius: "10px" }} />
                          </Space>
                        ))}
                      </Flex>
                    
                  </Card>
                }
                {dataRecord.patientSymptomsFull &&
                  <Card title="Chief Complaints">
                    <div style={{maxHeight: "100px", overflowY: "auto"}}>
                        <pre>{dataRecord.patientSymptomsFull}</pre>
                    </div>
                    
                  </Card>
                  }
                 {dataRecord.patientEHRDetailsFull && 
                  <Card title="Pateint Details from EHR">
                    <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.patientEHRDetailsFull) }} />
                  </Card>
                  }
            </Col>
            <Col span={16} style={{padding: "10px"}}>
              <Card title="Generated Medical Notes">
                {imageAnalysisData.length > 0 ? 
                <Collapse accordion style={{ marginBottom: "30px" }} >     
                {imageAnalysisData.map((response:any, index:any) => (
                  <Panel header={"Analysis for image "+String(Number(index)+1)} key={index + 1}>
                    <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={response[3]}/><DownloadDocButton markdownText={response[3]} fileName={`Image ${String(Number(index)+1)} Analysis report`} /></Flex>
                    <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(response[3]) }} />
                    {response[4]?<Alert message="Feedback already submitted." type="success" showIcon closable />:<Feedback submitionType="single" id={response[0]} />}
                  </Panel>
                ))}
                </Collapse>
                : ""}
                <Collapse accordion >     
                  {dataRecord.aiGeneratedAnalysisResultFull? 
                  <Panel header="AI Generated Medical Notes" key="01">
                    <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={dataRecord.aiGeneratedAnalysisResultFull}/><DownloadDocButton markdownText={dataRecord.aiGeneratedAnalysisResultFull} fileName={"AI Generated Medical Notes"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.aiGeneratedAnalysisResultFull) }} />{dataRecord.feedbackFull?<Alert message="Feedback already submitted." type="success" showIcon closable />:<Feedback submitionType="overall" id={dataRecord.processId} />}
                  </Panel>
                  :""}
                  {dataRecord.augmentedAnalysisResultFull? 
                  <Panel header="Final Medical Notes" key="02">
                    <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={dataRecord.augmentedAnalysisResultFull}/><DownloadDocButton markdownText={dataRecord.augmentedAnalysisResultFull} fileName={"Final Medical Notes"} /></Flex><div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.augmentedAnalysisResultFull) }} />
                  </Panel>
                  :""}
                </Collapse>
              </Card>
            </Col>
          </Row>)
        case "analyze":
          return (<Row>
            <Col span={12} style={{padding: "10px"}}>
            {dataRecord.medicalNotesFull? 
            <Card title="Your Medical Notes">
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.medicalNotesFull) }}  />
            </Card>
            :""}
            </Col>
            <Col span={12} style={{padding: "10px"}}>
            {dataRecord.analysisReportFull? 
            <Card title="Analysis Report">
                <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={dataRecord.analysisReportFull}/><DownloadDocButton markdownText={dataRecord.analysisReportFull} fileName={"Medical Note Analysis Report"} /></Flex>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.analysisReportFull) }}  />
                {dataRecord.feedbackFull?<Alert message="Feedback already submitted." type="success" showIcon closable />:<Feedback submitionType="analyze" id={dataRecord.key} />}
            </Card>
            :""}
            </Col>
          </Row>)
        case "synopsis":
          return (<Row>
            <Col span={12} style={{padding: "10px"}}>
            {dataRecord.patientHistoryFull? 
            <Card title="Your Medical Notes">
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.patientHistoryFull) }}  />
            </Card>
            :dataRecord.patientEHRDetails? 
            <Card title="Pateint Details from EHR">
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(String(dataRecord.patientEHRDetails)) }}  />
            </Card>
            :""}
            </Col>
            <Col span={12} style={{padding: "10px"}}>
            {dataRecord.synopsisFull? 
            <Card title="Generated Synopsis">
                <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={dataRecord.synopsisFull}/><DownloadDocButton markdownText={dataRecord.synopsisFull} fileName={"Synopsis"} /></Flex>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.synopsisFull) }}  />
                {dataRecord.feedbackFull?<Alert message="Feedback already submitted." type="success" showIcon closable />:<Feedback submitionType="synopsis" id={dataRecord.key} />}
            </Card>
            :""}
            </Col>
          </Row>)
          
        case "synopsis-audio":
          return (<Row>
            <Col span={12} style={{padding: "10px"}}>
              {dataRecord.audioUrl && 
                <Card title="Audio">
                  <audio src={`data:audio/mp3;base64,${dataRecord.audioUrl}`} controls style={{width: "100%"}} />
                </Card>
              }
              {dataRecord.audioTranscriptionFull &&
                <Card title="Audio Transcription" style={{marginTop: "10px"}}>
                  <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.audioTranscriptionFull) }} />
                </Card>
              }
            </Col>
            <Col span={12} style={{padding: "10px"}}>
            {dataRecord.synopsisFull? 
            <Card title="Generated Synopsis">
                <Flex style={{ justifyContent: 'flex-end' }}><CopytoClipboard text={dataRecord.synopsisFull}/><DownloadDocButton markdownText={dataRecord.synopsisFull} fileName={"Synopsis"} /></Flex>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(dataRecord.synopsisFull) }}  />
                {dataRecord.feedbackFull?<Alert message="Feedback already submitted." type="success" showIcon closable />:<Feedback submitionType="synopsis_from_audio" id={dataRecord.key} />}
            </Card>
            :""}
            </Col>
          </Row>)
          
        default:
          return null;

      }
    }
    return (
      <>
        <Modal title={modalTitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered width="80%" style={{marginLeft: 200}} maskClosable={false} keyboard={false} >
            <div style={{maxHeight: "80vh", overflowY: "auto"}}>
              {getModalUI()}
            </div>
          
        </Modal>
      </>
    );
}

export default ViewContentModal