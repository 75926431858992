import { EditOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { useAuth } from '../../auth/AuthContext';

interface DeIDButtonProps{
    text: any;
    id: any;
    deidType: any;
    setDeIDResponse: any;
    setIsLoading: any
}

const DeIDButton = ({text, id, deidType, setDeIDResponse, setIsLoading}: DeIDButtonProps) => {   
    const auth = useAuth(); 
    const handlDeid = async () => {
        setIsLoading(true);
        try {
            let formData = new FormData();
            formData.append("process_id", id);
            formData.append("clinical_note", text);
            formData.append("deid_type", deidType);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/deid`, {
                method: 'POST', // or 'PUT'
                headers: { 
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setDeIDResponse(data)
            message.success('Your synopsis has been anonymized successfully');
        } catch (error) {
            console.error('Failed to anonymize the synopsis: ', error);
            message.error('Failed to anonymize the synopsis.');
        }
        setIsLoading(false);
    };
    return (
        <Tooltip placement="top" title="De-ID">
          <Button type="primary" shape="circle" style={{marginRight: "20px", cursor: "pointer"}} danger onClick={handlDeid}><EyeInvisibleOutlined /></Button>
        </Tooltip>
    )
}

export default DeIDButton